import React from 'react';

const ImageLink = ({ imageUrl, linkUrl }) => {
  const handleClick = () => {
    // Navigate to the specified linkUrl
    window.location.href = linkUrl;
  };

  return (
    <div>
      <img src={imageUrl} alt="Clickable Image" onClick={handleClick} style={{ cursor: 'pointer' }} />
    </div>
  );
};

export default ImageLink;
