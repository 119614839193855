import React, { useState, useEffect } from 'react'
import ImageLink from './ImageLink';
import '../App.css';
import logo from '../images/2 copy.png';
import gallery from '../images/Gallery.png';
import ttl1 from '../images/Title (1).png';
import idfs from '../images/ideas. FROM SPACE.png';
import eye from '../images/Untitled-1 1.png';
import ourskills from '../images/ourSkills.png';
import skills from '../images/skills2.png';
import title from "../images/Title.png";
import skillscol from '../images/skillssColumn.png';
import pc from '../images/2.png';
import pc2 from '../images/3.png';
import stats from '../images/STSA.drawio.png';
import tools from '../images/3 copy.png';
import footer from '../images/6.png';
import ring from '../images/4.png';
import clients from '../images/5.png';
import contactus from '../images/7.png';
import clientsays from '../images/client.png';
import process from '../images/process.png';
export default function UserWindow() {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  
  useEffect(() => {
    window.addEventListener('resize', setDimension);
    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])
  return (
// <div className="App">
<body className="App-header">
<img src={logo} alt="logo" />
<div className='divs'>
  <img src={title} alt="title" />  
</div>

<img src={process} className="App-logo" alt="background" />
<ImageLink imageUrl={tools} className="App-logo" alt="tools" linkUrl={"https://instagram.com/sevenpixell?igshid=OGQ5ZDc2ODk2ZA=="}/>

<div className='divs'>
  <img src={ttl1} alt="title1" />
</div>

<div className='divs'>
  <img src={gallery} className="App-logo" alt="gallery" />
</div>

<div className="ideaFromSpaceTxt">
  <img src={idfs} alt="ideasFromSpace" />
  <p>"My designs orbit in the realm of cosmic creativity, weaving space-inspired ideas into visually captivating expressions."</p>
</div>

<div className='divs'>
  <img src={eye} alt="eye" />
</div>

<div className='skills'>
  <img src={ourskills} alt="ourSkills" />
  <img src={skillscol} alt="skillsColumn" />
  <img src={skills} alt="skills" />
</div>

<div className='postDesign'>
  <img src={pc2} alt="pc2" /><img src={pc} alt="pc1" />
</div>
<div>
    <img src={ring} alt='astroRing'></img>
</div>        
<div className='divs'>
  <img src={stats} alt="stats" />
</div>        
<img src={clientsays} className="App-logo" alt="background" />
<div className='divs'>
  <img src={clients} className="App-logo" alt="logo" />
</div>


<ImageLink imageUrl={contactus} linkUrl="https://wa.me/97471102933" />
<img src={footer} className="App-logo" alt="logo" />
</body>
// </div>
  )
}