import React from 'react';
import ScreenSize from './components/UserWindow.js';
function App() {
  return (
    <div className='App'>
      <ScreenSize/>
    </div>
  );
}

export default App;
